import FormPage from '@/components/FormPage'
import security from '@/security'
import { createGetParams, createPostParams } from '@/rest'

const formTitle = 'Annual Subscription'
const defaultPin = 'D225'

export default {
  extends: FormPage,
  data () {
    return {
      name: null,
      street1: null,
      street2: null,
      city: null,
      postalCode: null,
      state: null,
      quantity: 1,
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Dealership', disabled: false, to: '/dealership_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      transferDetails: {
        fields: {
          d225Pin: {
            name: defaultPin + ' balance',
          },
          dealer: {
            component: () => import(/* webpackChunkName: "dealerSubscription" */ '@/components/DealerSubscription'),
            name: 'To dealer Id',
            props: {
              persistentHint: true,
              label: 'Enter Dealer Id',
              noSelf: true,
              claimer: security.me.claimer(),
              required: true,
              rules: [val => !!val || 'Dealer Id required!'],
            },
          },
        },
        title: 'Dealer subscription details',
      },
      submission: {
        fields: {
          dealer: {
            name: 'To dealer Id',
          },
          annualSubscription: {
            name: 'Annual Subscription',
          },

        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        transferDetails: this.transferDetails,
        submission: this.submission,
      }
    },
  },
  mounted: function () {
    const params = createGetParams({
      dealership_pin: true,
    })
    this.$rest.get('getDealerResources.php', params).then(response => {
      if (security.me.pureNuDealer()) {
        this.transferDetails.fields.nuPin.value = response.data.nuPin
        this.transferDetails.fields.bPin = {}
        this.transferDetails.fields.sdPin = {}
        this.transferDetails.fields.dealerPin = {}
        this.transferDetails.fields.d225Pin = {}
        this.transferDetails.fields.dpPin = {}
      } else {
        this.transferDetails.fields.d225Pin.value = response.data.d225Pin ? response.data.d225Pin : 0
      }
    })
    this.validateD225Pin()
  },
  watch: {
    'transferDetails.fields.dealer.value': function (val) {
      this.submission.fields.dealer.value = val

      if (val) {
        const params = createGetParams({
          target: this.transferDetails.fields.dealer.value,
        })
        this.$rest.get('getDealerSubscription.php', params)
          .then(response => {
            this.submission.fields.annualSubscription.value = response.data.nextKpiStart + ' until ' + response.data.endKpi
          }).catch(() => {
          // ignore
          })
      }
    },
  },
  methods: {
    validateD225Pin: function () {
      return this.d225Pin <= 0 ? 'Insufficient ' + defaultPin + ' PIN!' : true
    },
    // Override
    async submit () {
      const params = createPostParams({
        action: 'annualSubscription',
        payload: {
          type: 'D225',
          target: this.transferDetails.fields.dealer.value,
          quantity: '1',
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to renew dealership was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
